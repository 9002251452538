<template>
  
    <div class="row mt-5">
          <div class="col-xl-7 col-lg-8">
            <h1 class="mb-5" >
              <em>МАРТ</em> - диджитал-компания с самыми современными инструментами поиска клиентов, которые позволяют обеспечить любой бизнес горячими клиентами
            </h1>
            <p class="mb-5 " >
              Получайте до 5-7 раз больше клиентов каждый день!<br>
              Без увеличения рекламного бюджета и скидок клиентам!<br><br>
            </p>
            <h1><em>Какие проблемы бизнеса мы решаем?</em></h1>
          </div>
          <div class="col-xl-5 col-lg-4 mt-lg-0 mt-5">
            <img src="../assets\MainPage\hero-img.png" alt="Изображение: Определено посетителей 298" class="img-fluid">
          </div>
    </div>

    <section class="solutionProblems">
      <div class="container solution problems-container position-relative">
        <div class="row">
          <div class="col ">
           <h1><p>Проблемы</p></h1>
          </div>
          <div class="col">
           <h1><p>Решения</p></h1>
          </div>
        </div>
        <div class="row">
          <div class=" text-white col text-justify mb-2 ">
            <h4 class ="fw-bold"><div class ="solution_group__item-title" ></div>Отсутствие собственного сайта<br></h4>
            Cобственник бизнеса не создает сайт из-за
            консервативных взглядов на рекламу или специфичности ниши
          </div>
          <div class=" text-white col text-justify mb-2">
            <h4 class= "fw-bold"><div class ="solution_group__item-title" ></div>Поиск сайтов конкурентов<br></h4>
            На каждого предпринимателя найдется его конкурент,
            с рекламных платформ которого представляется возможным парсить данные потенциальных клиентов,
            с помощью сервиса
          </div>
        </div>
        
        <div class="row">
          <div class=" text-white col text-justify mb-2">
            <h4 class = "fw-bold "><div class ="solution_group__item-title" ></div>Низкий ROI (возврат инвестиций на каждый рубль в рекламу)<br></h4>
            Текущие каналы привлечения клиентов требуют огромных вложений, но не дают соответствующего "выхлопа",
            то есть нужного объема заявок и как следсвие продаж
          </div>
          <div class="text-white col text-justify mb-2">
            <h4 class="fw-bold"><div class ="solution_group__item-title" ></div>Снижение рекламных издержек<br></h4>
            Производится не за счет отключения каналов, или уменьшения рекламного бюджета вообще,
            а за счет подключения эффективного и высоконверсионного канала в виде сервиса, с самой низкой стоимостью за 1-го потенциального клиента
          </div>
        </div>
     
        <div class="row">
          <div class=" text-white col text-justify mb-2">
          <h4 class = "fw-bold"><div class ="solution_group__item-title" ></div>Отдел продаж «простаивает»<br></h4>
            Вы не получаете достаточно обращений,
            чтобы «напитать» ими отдел продаж
          </div>
          <div class="text-white col text-justify mb-2">
          <h4 class = "fw-bold"><div class ="solution_group__item-title" ></div>Отдел продаж «напитан» обращениями<br></h4>
            У менеджеров становится работы в 2-3 раза больше,
            чем было до этого! Вы получаете много теплых контактов
          </div>
        </div>
      </div>
      
    </section>


    <section class="minkifr">
      <div class=" container minkifr-container position-relative">
        <div class="row">
          <div class="text-white col-lg-6 text-justify">
            <h2>Наш сервис рекомендован к использованию Минцифры</h2><br>
            Министерство цифрового развития создало реестр российского ПО, которое рекомендуют к использованию. Чтобы попасть в реестр необходимо пройти строгий отбор:<br>
            ► Предоставить код программного обеспечения на анализ;<br>
            ► Сравнение функциональных характеристик ПО с заявлениями, указанными на сайте;<br>
            ► Информация о соответствии программного обеспечения требованиям, установленным пунктом 5 Правил формирования и ведения реестров;<br>
            ► Предоставление сведений о программных модулях, являющихся неотъемлемыми компонентами программного обеспечения.<br>

            Кликните по ссылке, чтобы посмотреть нашу компанию в реестре: reestr.digital.ru
          </div>
          <div class="col-lg-5 mt-lg-0 mt-5">
            <img src="../assets/MainPage/mincifr.png" alt="Изобраение: Минцифры" class="img-fluid">
          </div>
        </div>
      </div>
    </section>

    <section class="sber">
      <div class="container sber-container position-relative">
        <div class="row">
          <div class=" text-white col-lg-7">
            <h2 class="mb-5"><em>МАРТ</em> - официальный партнер проекта Сбера</h2>
            <p class="mb-5">
              Министерство экономического развития совместно со Сбером создало специальную
              платформу знаний и сервисов для бизнеса «Деловая&nbsp;среда»
              <br><br>
              Цель этой платформы - помочь предпринимателям в более эффективном запуске и
              управлению бизнесам АО «Деловая среда» выбрала МАРТ официальным&nbsp;партнером по направлению «Маркетинг»
            </p>
            <div class="d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-md-start">
              <button type="button" class="btn btn-primary w-100 mb-2 " data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                Посмотреть информацию о нас<br>
                на сайте АО «Деловая среда»
              </button>
              <div class="ml-2"></div>
              <button type="button" class="btn btn-primary w-100 mb-2 " data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                Посмотреть сертификат<br>
                официального партнера
              </button>
            </div>
          </div>
          <div class="col-lg-5 mt-lg-0 mt-5">
            <img src="../assets\MainPage\decor/paper.png" alt="Изобраение: сертификат" class="img-fluid">
          </div>
        </div>
      </div>
    </section>

    <section class="partners">
      <div class="text-white container">
        <h2 class="text-uppercase mb-4">
          Клиенты, подключившие<br>
          продукты нашей компании
        </h2>
        <span class="d-block mb-4">Которые используют ПК "МАРТ"</span>
        <div class="row justify-content-center">
          <div class="col-lg-9 d-flex justify-content-xxl-between justify-content-around flex-wrap">
            <img src="../assets/MainPage/partners/partner-1.png" alt="Газпромбанк" class="mb-5">
            <img src="../assets/MainPage/partners/partner-2.png" alt="Raiffeisen Bank" class="mb-5">
            <img src="../assets/MainPage/partners/partner-3.png" alt="СовкомБанк" class="mb-5">
            <img src="../assets/MainPage/partners/partner-4.png" alt="Skillbox" class="mb-5">
            <img src="../assets/MainPage/partners/partner-5.png" alt="Ренессанс" class="mb-5">
            <img src="../assets/MainPage/partners/partner-6.png" alt="skyeng" class="mb-5">
            <img src="../assets/MainPage/partners/partner-7.png" alt="МТС Касса" class="mb-5">
            <img src="../assets/MainPage/partners/partner-8.png" alt="Уралсиб Банк" class="mb-5">
            <img src="../assets/MainPage/partners/partner-9.png" alt="WorldClass" class="mb-5">
          </div>
        </div>
        <div class="info">
          <div class="row">
            <div class="col-lg-6 text-center mb-4 text-lg-start mb-lg-0">
              <h5 class="mb-3">Это не просто логотипы на сайте.</h5>
              <p class="mb-0">
                Хотите узнать детали проектов с данными компаниями?<br class="d-none d-xl-block">
                Оставьте заявку и мы с радостью расскажем о наших кейсах
              </p>
            </div>
            <div class="col-lg-6 d-flex align-items-center justify-content-center">
              <button type="button" class="btn btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                Оставить заявку 
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="tariff">
      <div class="container">
        <h2 class="text-white mb-3">Тарифный план Пиксель</h2>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <td></td>
                <td><div>Старт</div></td>
                <td><div>Стандарт</div></td>
                <td><div>Профи</div></td>
                <td><div>Макси</div></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Телефонные номера заинтересованных клиентов</td>
                <td>300</td>
                <td>800</td>
                <td>2 500</td>
                <td>12 000</td>
              </tr>
              <tr>
                <td>Виджет обратного звонка</td>
                <td><img class = "com" src = "../assets\MainPage\com.png"/></td>
                <td><img class = "com" src = "../assets\MainPage\com.png"/></td>
                <td><img class = "com" src = "../assets\MainPage\com.png"/></td>
                <td><img class = "com" src = "../assets\MainPage\com.png"/></td>
              </tr>
              <tr>
                <td>Интеграция с CRM-системой</td>
                <td><img class = "com" src = "../assets\MainPage\com.png"/></td>
                <td><img class = "com" src = "../assets\MainPage\com.png"/></td>
                <td><img class = "com" src = "../assets\MainPage\com.png"/></td>
                <td><img class = "com" src = "../assets\MainPage\com.png"/></td>
              </tr>
              <tr>
                <td>Техническая поддержка</td>
                <td><img class = "com" src = "../assets\MainPage\com.png"/></td>
                <td><img class = "com" src = "../assets\MainPage\com.png"/></td>
                <td><img class = "com" src = "../assets\MainPage\com.png"/></td>
                <td><img class = "com" src = "../assets\MainPage\com.png"/></td>
              </tr>
              <tr>
                <td>Доступ в закрытый клуб клиентов</td>
                <td><img class = "com" src = "../assets\MainPage\com.png"/></td>
                <td><img class = "com" src = "../assets\MainPage\com.png"/></td>
                <td>Мастер-группа</td>
                <td>Мастер-группа</td>
              </tr>
              <tr>
                <td>Сценарий продаж</td>
                <td>Стандартный</td>
                <td>Индивидуальный</td>
                <td>Индивидуальный</td>
                <td>Индивидуальный</td>
              </tr>
              <tr>
                <td>Личный менеджер внедрения</td>
                <td><img class = "x" src = "../assets\MainPage\reject.png"/></td>
                <td><img class = "x" src = "../assets\MainPage\reject.png"/></td>
                <td><img class = "com" src = "../assets\MainPage\com.png"/></td>
                <td><img class = "com" src = "../assets\MainPage\com.png"/></td>
              </tr>
              <tr>
                <td>Аудит сайта и рекламной кампании</td>
                <td><img class = "x" src = "../assets\MainPage\reject.png"/></td>
                <td><img class = "x" src = "../assets\MainPage\reject.png"/></td>
                <td><img class = "com" src = "../assets\MainPage\com.png"/></td>
                <td><img class = "com" src = "../assets\MainPage\com.png"/></td>
              </tr>
              <tr>
                <td>Личный менеджер по обработке телефонов</td>
                <td><img class = "x" src = "../assets\MainPage\reject.png"/></td>
                <td><img class = "x" src = "../assets\MainPage\reject.png"/></td>
                <td><img class = "x" src = "../assets\MainPage\reject.png"/></td>
                <td><img class = "com" src = "../assets\MainPage\com.png"/></td>
              </tr>
              <tr>
                <td>Гарантия продаж по договору, х6 от стоимости тарифа</td>
                <td><img class = "x" src = "../assets\MainPage\reject.png"/></td>
                <td><img class = "x" src = "../assets\MainPage\reject.png"/></td>
                <td><img class = "x" src = "../assets\MainPage\reject.png"/></td>
                <td><img class = "com" src = "../assets\MainPage\com.png"/></td>
              </tr>
              <tr>
                <td>Цена за 1 заинтересованного клиента</td>
                <td>123 р.</td>
                <td>95 р.</td>
                <td>65,2 р.</td>
                <td>51,6 р.</td>
              </tr>
              <tr class="bold">
                <td><span>Стоимость</span></td>
                <td><span>37 000</span> р.</td>
                <td><span>76 000</span> р.</td>
                <td><span>163 000</span> р.</td>
                <td><span>620 000</span> р.</td>
              </tr>
              <tr class="btns">
                <td></td>
                <td><button type="button" class="btn btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                  Подключить 
          </button></td>
                <td><button type="button" class="btn btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                  Подключить 
          </button></td>
                <td><button type="button" class="btn btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                  Подключить 
          </button></td>
                <td><button type="button" class="btn btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                  Подключить 
          </button></td>
              </tr>
              <tr>
                <td>Количество диалогов с клиентами</td>
                <td>300</td>
                <td>800</td>
                <td>2 500</td>
                <td>12 000</td>
              </tr>
              <tr>
                <td>Стоимость колл центра</td>
                <td>30 000 р.</td>
                <td>65 000 р.</td>
                <td>195 000 р.</td>
                <td>905 000 р.</td>
              </tr>
              <tr class="btns">
                <td></td>
                <td>
                  <button type="button" class="btn btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    Подключить 
                  </button>
                </td>
                <td>
                  <button type="button" class="btn btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    Подключить 
                  </button>
                </td>
                <td>
                  <button type="button" class="btn btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    Подключить 
                  </button>
                </td>
                <td>
                  <button type="button" class="btn btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    Подключить 
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
 

        <div class="container">
          <h2 class="text-white mb-3">Тарифный план ГЦК+колл-центр</h2>
          <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <td>Тариф</td>
                <td><div>1000 номеров + обработка ПИЛОТНЫЙ</div></td>
                <td><div>3000 номеров + обработка СТАНДАРТНЫЙ</div></td>
                <td><div>6000 номеров + обработка ПРОФИ</div></td>
                <td><div>10000 номеров + обработка МАКСИ</div></td>
                <td><div>Индивидуальный:От 50,000 номеров</div></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ГЦК</td>
                <td>70 000 р.</td>
                <td>180 000 р.</td>
                <td>350 000 р.</td>
                <td>500 000 р.</td>
                <td>Индивидуально</td>
              </tr>
              <tr>
                <td>Колл центр</td>
                <td>63 200 р.</td>
                <td>190 000 р.</td>
                <td>380 000 р.</td>
                <td>520 000 р.</td>
                <td>Индивидуально
                  
                </td>
              </tr>
              <tr>
                <td>Цена 1 контакта с обработкой</td>
                <td>133,2 р.</td>
                <td>123,3 р.</td>
                <td>121,1 р.</td>
                <td>102 р.</td>
                <td>Индивидуально</td>
              </tr>
              <tr>
                <td>Стоимость пакета</td>
                <td>133 200 р.</td>
                <td>370 000 р.</td>
                <td>730 000 р.</td>
                <td>1 020 000 р.</td>
                <td>Индивидуально</td>
              </tr>
              <tr class="btns">
                <td></td>
                <td>
                  <button type="button" class="btn btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    Подключить 
                  </button>
                </td>
                <td>
                  <button type="button" class="btn btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    Подключить 
                  </button>
                </td>
                <td>
                  <button type="button" class="btn btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    Подключить 
                  </button>
                </td>
                <td>
                  <button type="button" class="btn btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    Подключить 
                  </button>
                </td>
                <td>
                  <button type="button" class="btn btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    Подключить 
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        </div>
      </div>
    </section>

    <section class="work">
      <div class="container p-3 p-sm-5">
        <h2 class="text-white text-uppercase text-lg-start">Как это работает?</h2>
        <div class="row">
          <div class="col-lg-6 blocks text-lg-start">
            <div class="mt-3 mb-3 p-2 block ">
              <h6 class="mb-2">01. Брифинг</h6>
              <p class="mb-0">
                Список сайтов, посетителей которых вы хотите начать собирать;

                Список телефонных номеров, на которые звонят ваши потенциальные клиенты;

                Сколько новых номеров потенциальных клиентов готовы обрабатывать ежедневно 
                (Мы можем настроить фильтр, что в день вы получали то количество потенциальных клиентов, которое можете обработать);

                Есть ли у вас CRM-система для удобной обработки данных/подключена ли туда телефония. 
                (У нас есть готовые интеграции с AmoCRM/Bitrix/Скорозвон/Открытое API. При этом, при необходимости, готовы бесплатно предоставить нашу CRM-систему).
              </p>
            </div>
            <div class="mt-3 mb-3 p-2 block" >
              <h6 class="mb-2">02. Заключение договора</h6>
              <p class="mb-0">
                Заключаем договор, где прописываем какие данные вы будете получать (на основе вашего брифа).

                Фиксируем конкретное количество потенциальных клиентов и твердую стоимость каждого контакта.
              </p>
            </div>
            <div class="mb-3 p-2 block" >
              <h6 class="mb-2">03. Подготовка сценария общения с клиентами</h6>
              <p class="mb-0">
                Мы запустили уже множество проектов и точно знаем как звонить тем, кто интересовался товарами/услугами в интернете, но не оставлял заявку конкретно в Вашу компанию.
              </p>
            </div>
            <div class="mb-3 p-2 block" >
              <h6 class="mb-2">04. Начало предоставления контактов потенциальных клиентов</h6>
              <p class="mb-0">
                В среднем, мы начинаем поставлять данные через 7-10 дней после заключения договора. Эти данные попадают в CRM-систему и дальше обрабатываются нашими/вашими сотрудниками.
              </p>
            </div>
            <div class="mb-3 p-2 block" >
              <h6 class="mb-2">05. Оперативный обмен обратной связью и корректировки</h6>
              <p class="mb-0">
                На этом этапе важно передавать информацию - как именно у Вас строится общение с потенциальными клиентами, чтобы можно было корректировать источники предоставления данных и повышать конверсию в горячих лидов.

                (Поскольку понятно, что не каждый, кто искал информацию в интернете по итогу станет вашим клиентом).
              </p>
            </div>
           
          </div>
          <div class="col-lg-5 offset-lg-1">
            <div class="img-wrapper h-100 d-flex align-items-center justify-content-center">
              <img src="../assets/MainPage/work/item-1.png" alt="" class="d-block">
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="form">
      <div class="container">
        <div class="row justify-content-between">
          <div class="text-white col-lg-6 text-center text-lg-start">
            <h2 class="text-uppercase mb-5">
              Получайте <em>до 300%</em><br>
              больше новых клиентов каждый день
            </h2>
            <p>
              Вам позвонят наши специалисты, мы проведем аудит вашей текущей ситуации и
              подготовим стратегию<br class="d-none d-xl-block">
              роста бизнеса на 25 - 300% за 2 месяца.
            </p>
          </div>
          <div class="col-lg-4">
            <form id="form2" class="mx-auto send-data">
              <input type="hidden" name="form_type" value="form_block1">
              <input type="text" name="phone" class="input phone form__field" id="customer_phone" value="+7" size="25" placeholder="+7 (___) ___-__-__" required="">
              <input type="radio" style="display: none" name="mode" id="is_world" value="world" checked=""><label for="is_world" style="display: none"></label>
              <input type="checkbox" id="phone_mask" style="display: none" checked="">
              <input type="email" name="email" placeholder="E-mail">
              <button type="submit" class="btn btn-lg"><span>Получить</span></button>
              <div class="text-left">
                <input type="checkbox" name="privacy" id="privacy-2" required="">
                <label for="privacy-2" class="text-left">
                  <a target="_blank" href="https://disk.yandex.ru/i/J7te5069-SvsHw" class="privacy-link" >Согласие на обработку</a>
                  заявок с сайта для компаний-клиентов
                </label>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>

    <section class="form-bottom">
      <div class="container p-3 p-sm-5">
        <div class="text-white row mb-5">
          <div class="col-xxl-7 col-lg-8 text-center text-lg-start">
            <h2 class="mb-4 text-uppercase" >
              <em>Индивидуальные условия -</em> для крупных клиентов
            </h2>
            <span class="d-block mb-3 text-start" >Оставьте заявку и получите специальное предложение</span>
          </div>
          <div class="col-xxl-5 col-lg-4 mt-4 m-lg-0 text-center">
            <img src="../assets/MainPage/content/form.png" alt="" class="img-fluid">
          </div>
        </div>
        <form id= "form" class="row mx-auto mx-lg-0 send-data">
          <input type="hidden" name="form_type" value="form_block1">
          <div class="col-lg-4 mb-3">
            <input type="text" name="phone" class="input phone form__field _req" id="customer_phone" value="+7" size="25" placeholder="+7 (___) ___-__-__" required="">
            <input type="radio" style="display: none" name="mode" id="is_world" value="world" checked=""><label for="is_world" style="display: none"></label>
            <input class = "_req" type="checkbox" id="phone_mask" style="display: none" checked="">
          </div>
          <div class="col-lg-4 mb-3">
            <input type="email" name="email" class = "_req _email" placeholder="E-mail">
          </div>
          <div class="col-lg-4">
            <button type="submit" class="btn btn-lg mb-2">Получить</button>
            <div>
              <input type="checkbox" name="privacy" id="privacy-3" required="">
              <label for="privacy-3">
                <a target="_blank" href="https://disk.yandex.ru/i/J7te5069-SvsHw" class="privacy-link"  >Согласие на обработку</a>
                заявок с сайта для компаний-клиентов
              </label>
            </div>
          </div>
        </form>
      </div>
    </section>

    
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>


<style scoped>
.modal-content {
    border: 1px solid #f4b859 !important;
    background-color: #282828 !important;
    color: #ffffff;
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
    outline: 0;
}

.bi-x-lg::before {
    content: "\f659";
}
.bi::before, [class^="bi-"]::before, [class*=" bi-"]::before {
    display: inline-block;
    font-display: block;
    font-family: bootstrap-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: -0.125em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.modal.show .modal-dialog {
    transform: none;
}
.modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0,-50px);
}
.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}

i {
    font-style: italic;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
.modal-close {
    color: #f4b859;
    background: transparent;
    border: none;
    padding: 0;
    font-size: 30px;
}

h5, h6{
  color: white;
}

.justify-content-between {
    justify-content: space-between!important;
}
.work .container {
    background-image: url(../assets/MainPage/work/form-bottom.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    background-color: #282828;
    overflow: hidden;
}

.work h6 {
    color: #f4b859;
}
.mb-2 {
    margin-bottom: 0.5rem!important;
}
h6 {
    font-size: 18px;
    font-weight: 600 !important;
}
body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    background-color: #282828;
    font-family: Times New Roman,Georgia,Serif;
    font-weight: 500;
    color: #ffffff;
    font-size: 18px;
    overflow-x: hidden;
}

.text-uppercase {
    text-transform: uppercase!important;
}

.mb-4 {
    margin-bottom: 1.5rem!important;
}

.h3, h3 {
    font-size: calc(1.3rem + .6vw);
}



h1 em, h2 em, h3 em {
    font-style: normal;
    color: #f4b859;
}


input[type=checkbox] + label {
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    font-weight: 400;
    font-size: 15px;
    color: #d0d0d0;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
.btn-lg {
    font-size: 20px;
    line-height: 24px;
    min-height: 77px;
    padding: 26px;
    width: 100%;
    max-width: 380px;
}
.btn {
    padding: 14px;
    min-height: 48px;
    border-radius: 10px;
    background-color: #f4b859;
    border: 1px solid #f4b859;
    font-size: 15px;
    line-height: 18px;
    font-weight: 600;
    color: #282828;
    cursor: pointer;
}

input[type=text], input[type=email] {
    padding: 25px;
    width: 100%;
    max-width: 380px;
    height: 76px;
    border-radius: 10px;
    background-color: #acacac;
    text-align: center;
    color: #e4e4e4;
    font-size: 20px;
    line-height: 24px;
}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.form-bottom .container {
    background-image: url(../assets/MainPage/bg/work-bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    background-color: #282828;
}

.sber-container {
    padding: 70px;
    background-image: url(../assets/MainPage/bg/sber-bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
}

.x{
  width: 32px;
  height: 32px;
}

.com{
  width: 32px;
  height: 32px;
}

h1 p {
  color: black;
  background-color: #f4b859;
  padding: 5px 15px;
  border-radius: 20px;
}

tr{
  border: 2px solid orange;
}
td{
  border: 2px solid orange;
  column-span: 100%;
}

p , h1 {
  color: white;
}


td > div{
  background-color: #2e3239;
  color: white;
}
tr > td{
  background-color: #2e3239;
  color: white;
  
}

p{
  font-size: 16pt;
}

.cardDiv{
  margin: 15px;
}
.card {
  margin: 5px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.privacy-link {
    text-decoration: underline;
}
a {
    cursor: pointer;
    text-decoration: none;
    color: #ffffff;
}

.btn-lg {
    font-size: 20px;
    line-height: 24px;
    min-height: 77px;
    padding: 26px;
    width: 100%;
    max-width: 380px;
}

input[type=checkbox] + label::after {
    content: "";
    position: absolute;
    left: 0;
    top: 5px;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background: transparent;
    border: solid 1px #f5b858;
}
*, ::after, ::before {
    box-sizing: border-box;
}
input[type=checkbox] {
    display: none;
}



input[type=checkbox]:checked + label::before {
    display: block;
}

input[type=checkbox] + label::before {
    content: "";
    position: absolute;
    display: none;
    left: 0;
    top: 5px;
    width: 21px;
    height: 15px;
    background-image: url(../assets/check.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

input[type=checkbox] + label {
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    font-weight: 400;
    font-size: 15px;
    color: #d0d0d0 ;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

h1, h2, h3 {
    font-weight: 700;
    font-size: 35px;
}

.form input {
    margin-bottom: 16px;
}

.form button {
    margin-bottom: 16px;
}
.text-start {
    text-align: left;
}
.mb-3 {
    margin-bottom: 1rem!important;
}
.d-block {
    display: block!important;
}
* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

section {
    padding: 70px 0;
    overflow-x: hidden;
}
@media only screen and (min-width: 992px){
.text-lg-start {
    text-align: left;
}



}
.text-center {
    text-align: center;
}

.text-justify{
    text-align: justify;
}

.solution_group__item-title {
    content: "";
    width: 6px;
    height: 6px;
    background-color: #f4b859;
    margin-bottom: 5px;
    margin-right: 15px;
    border-radius: 3px;
    display:inline-block;
}

.ml-2{
  margin-left: 2em;
}


</style>
