import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

createApp(App).use(router).mount('#app')




document.addEventListener("DOMContentLoaded", function(){
    const form = this.getElementById("form");
    if(form){
        form.addEventListener('submit', formSend);
    }
    async function formSend(e){
        e.preventDefault();
        let formData = new FormData(form);

       
        let response = await fetch('sendmail.php',{
            method: 'POST',
            body:formData
        });
        if(response.ok){
            
            alert("Сообщение отправленно") ;
        }else{
            alert("Ошибка!!!!");
        }
    }
});

document.addEventListener("DOMContentLoaded", function(){
    const form2 = this.getElementById("form2");
    if(form2){
        form2.addEventListener('submit', formSend);
    }
    async function formSend(e){
        e.preventDefault();
        let formData2 = new FormData(form2);

       
        let response2 = await fetch('sendmail.php',{
            method: 'POST',
            body:formData2
        });
        if(response2.ok){
            
            alert("Сообщение отправленно");
        }else{
            alert("Ошибка!!!!");
        }
    }
});


document.addEventListener("DOMContentLoaded", function(){
    const form3 = this.getElementById("form3");
    if(form3){
        form3.addEventListener('submit', formSend);
    }
    async function formSend(e){
        e.preventDefault();
        let formData3 = new FormData(form3);

       
        let response3 = await fetch('sendmail.php',{
            method: 'POST',
            body:formData3
        });
        if(response3.ok){
            alert("Сообщение отправленно");
        }else{
            alert("Ошибка!!!!");
        }
    }
});