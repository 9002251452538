import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import AboutService from '../views/AboutService.vue'
import ReviewsView from '../views/ReviewsView.vue'
import ServiceView from '../views/ServiceView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/service',
    name: 'service',
    component: ServiceView
  },
  {
    path: '/reviews',
    name: 'reviews',
    component: ReviewsView
  },
  {
    path: '/aboutservice',
    name: 'aboutservice',
    component: AboutService
  }

]

const router = createRouter({
  mode: "hash",
  history: createWebHistory(process.env.BASE_URL),
  routes
})



export default router
