<template>
	<div class="container">

		<div class="theme-title-one text-center pb-80">

			<p style="margin-top: 40px; font-size:32pt; font-weight: bold;">Примеры звонков по обработке полученных заявок
			</p>

			<p style="margin-top: 40px;">Важно понимать, что мы советуем прорабатывать номера, согласно инструкциям. Однако,
				здесь мы показываем примеры живых диалогов клиентов, где менеджеры, в том числе, совершают описанные ошибки,
				но даже в этом случае добиваются результата. Цель демонстраций диалогов - посмотреть на реакции посетителей,
				а инструкцию как звонить просим использовать нашу для достижения лучшего результата.</p>


		</div>
		<div class="element-section mb-0">
			<div class="container">
				<div class="row feature-block-wrapper">


					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Винтовые сваи</h5>

							<audio controls="" class="w-100 p-2" style="margin-top: 20px;">
								<source src="../assets/AboutServicePage\audio\1.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>


					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Школа Dj</h5>

							<audio controls="" class="w-100 p-2" style="margin-top: 20px;">
								<source src="../assets/AboutServicePage/audio/2.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>


					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Установка лестниц</h5>

							<audio controls="" class="w-100 p-2"  style="margin-top: 20px;">
								<source src="../assets/AboutServicePage/audio/3.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Банкротство</h5>

							<audio controls="" class="w-100 p-2"  style="margin-top: 20px;">
								<source src="../assets/AboutServicePage/audio/4.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>


					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Откуда мой номер</h5>

							<audio controls="" class="w-100 p-2" style="margin-top: 20px;">
								<source src="../assets/AboutServicePage/audio/5.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>




					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Рефинансирование</h5>

							<audio controls="" class="w-100 p-2" style="margin-top: 20px;">
								<source src="../assets/AboutServicePage/audio/6.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>


					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Продажа роботов</h5>

							<audio controls="" class="w-100 p-2" style="margin-top: 20px;">
								<source src="../assets/AboutServicePage/audio/7.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>


					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Удаление запахов</h5>

							<audio controls="" class="w-100 p-2" style="margin-top: 20px;">
								<source src="../assets/AboutServicePage/audio/8.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Ремонт квартир<br><br></h5>


							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/50_8.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>



					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Наружная реклама<br><br></h5>

							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/51_1.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>



					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Франшиза парикмахерских<br><br></h5>

							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/52_1.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>


					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Мед. центр</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/53_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/53_2.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>


					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Регистрация дома и пр.</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/54_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/54_2.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>


					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Банковские гарантии</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/50_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/50_2.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Оборудование для клиники</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/56_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/56_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/56_3.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Ремонт квартир<br><br></h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/74_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/74_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/74_3.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>


					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Спорт. оборудование<br><br></h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/58_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/58_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/58_3.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Автомобильный бизнес</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/59_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/59_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/59_3.mp3" type="audio/mpeg">
							</audio>

							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/59_4.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>


					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Покупка авто</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/60_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/60_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/60_3.mp3" type="audio/mpeg">
							</audio>

							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/60_4.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>



					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Образование</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/61_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/61_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/61_3.mp3" type="audio/mpeg">
							</audio>

							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/61_4.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Батуты</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/62_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2"  style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/62_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/62_3.mp3" type="audio/mpeg">
							</audio>

							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/62_4.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Школа программистов</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/63_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/63_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/63_3.mp3" type="audio/mpeg">
							</audio>

							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/63_4.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>


					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Автомобили</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/64_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/64_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/64_3.mp3" type="audio/mpeg">
							</audio>

							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/64_4.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>


					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Окна<br><br></h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/65_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/65_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/65_3.mp3" type="audio/mpeg">
							</audio>

							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/65_4.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>



					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Строительство<br><br></h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/66_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/66_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/66_3.mp3" type="audio/mpeg">
							</audio>

							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/66_4.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>



					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Строительные материалы</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/67_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/67_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/67_3.mp3" type="audio/mpeg">
							</audio>

							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/67_4.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>



					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Обучение на агента по недвижимости</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/68_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/68_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/68_3.mp3" type="audio/mpeg">
							</audio>

							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/68_4.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>



					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Окна<br><br></h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/69_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/69_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/69_3.mp3" type="audio/mpeg">
							</audio>

							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/69_4.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>



					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Образование<br><br></h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/70_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/70_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/70_3.mp3" type="audio/mpeg">
							</audio>

							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/70_4.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>


					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Металлопрокат<br><br></h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/71_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/71_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/71_3.mp3" type="audio/mpeg">
							</audio>

							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/71_4.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>



					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Запчасти для сельхоз. техники</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/72_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/72_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/72_3.mp3" type="audio/mpeg">
							</audio>

							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/72_4.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>



					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Недвижимость<br><br></h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/73_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/73_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/73_3.mp3" type="audio/mpeg">
							</audio>

							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/73_4.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>


					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Подключение интернет эквайринга на сайте</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/44_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/44_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/44_3.mp3" type="audio/mpeg">
							</audio>

							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/44_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>



					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Светильники<br><br></h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/75_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/75_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/75_3.mp3" type="audio/mpeg">
							</audio>

							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/75_4.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Юр. услуги<br><br></h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/76_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/76_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/76_3.mp3" type="audio/mpeg">
							</audio>

							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/76_4.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Котлы<br><br></h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/85_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/85_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/85_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/85_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Школа программирования</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/78_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/78_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/78_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/78_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Шины для авто<br><br></h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/79_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/79_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/79_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/79_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Светильники</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/80_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/80_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/80_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/80_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Образование</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/81_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/81_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/81_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/81_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Зоомагазин</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/82_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/82_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/82_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/82_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Ворота</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/93_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/93_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/93_3.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>
					<!-- end OK  -->

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Офисная мебель</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/41_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/41_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/41_3.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Юр. услуги</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/84_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/84_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/84_3.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Вскрытие замков<br><br></h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/83_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/83_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/83_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/83_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Рециркуляторы<br><br></h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/86_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/86_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/86_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/86_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Школа программирования</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/87_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/87_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/87_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/87_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Кондиционеры<br><br></h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/40_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/40_2.mp3" type="audio/mpeg">
							</audio>

						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Франшиза парикмахерских</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/88_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/88_2.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Строительство домов<br><br></h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/114_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/114_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/114_3.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Резиновое покрытие</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/89_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/89_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/89_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/89_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">ЖК</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/90_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/90_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/90_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/90_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Газ</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/91_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/91_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/91_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/91_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Курсы косметологии</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/92_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/92_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/92_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/92_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Водоснабжение</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/94_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/94_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/94_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/94_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Котлы</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/95_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/95_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/95_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/95_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Покупка недвижимости</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/96_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/96_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/96_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/96_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Получение кредита</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/98_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/98_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/98_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/98_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Фитнес клуб</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/99_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/99_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/99_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/99_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Недвижимость</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/100_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/100_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/100_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/100_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Интернет-магазин</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/101_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/101_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/101_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/101_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Автомобили</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/102_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/102_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/102_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/102_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Сувенирная продукция</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/103_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/103_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/103_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/103_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Постельное белье</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/104_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/104_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/104_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/104_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Ремонт квартир</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/105_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/105_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/105_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/105_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Школа балета</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/106_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/106_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/106_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/106_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Автомобили</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/107_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/107_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/107_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/107_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Ипотечное кредитование</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/108_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/108_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/108_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/108_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Школа ин.яз.</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/109_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/109_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/109_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/109_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Газификация</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/110_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/110_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/110_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/110_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Котлы</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/111_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/111_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/111_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/111_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Косметология<br><br></h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/112_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/112_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/112_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/112_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Курсы программирования</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/113_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/113_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/113_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/113_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Штукатурка и шпаклевка<br><br></h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/115_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/115_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/115_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/115_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Кредит</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/116_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/116_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/116_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/116_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Недвижимость</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/117_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/117_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/117_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/117_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Тур.оператор</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/118_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/118_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/118_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/118_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Кредит</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/119_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/119_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/119_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/119_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Двери</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/120_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/120_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/120_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/120_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Школа ин. языка</h5>

							Звонок 1:
							<audio controls="" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/121_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/121_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/121_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/121_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Ремонт квартир</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/122_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/122_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/122_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/122_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Авто трейд ин</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/123_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/123_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/123_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/123_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Котлы</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/127_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/127_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/127_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/127_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Школа балета</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/124_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2"  style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/124_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/124_3.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Карнизы</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/125_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/125_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/125_3.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Бани и бочки </h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/143_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/143_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/143_3.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Антиквариат</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/146_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/146_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/146_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/146_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Клуб англ. яз.</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/128_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/128_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/128_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/128_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Бани-бочки</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/129_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/129_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/129_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/129_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Аукцион</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/130_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/130_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/130_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/130_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Пружины</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/131_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/131_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/131_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/131_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Аренда авто</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/132_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/132_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/132_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/132_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Двери</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/133_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/133_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/133_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/133_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Кредит</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/134_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/134_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/134_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/134_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Пружины</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/135_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/135_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/135_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/135_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Выпуск электронной подписи</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/137_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/137_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/137_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/137_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Поднятие мощности двигателя</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/138_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/138_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/138_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/138_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Косметологические аппараты</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/140_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/140_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/140_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/140_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Авто</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/136_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/136_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/136_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/136_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Школа ин. языков</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/139_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/139_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/139_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/139_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Карнизы</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/141_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/141_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/141_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/141_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Трейд ин</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/142_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/142_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/142_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/142_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Тахографы</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/144_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/144_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/144_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/144_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Ремонт квартир</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/145_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/145_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/145_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/145_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Курсы программирования</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/126_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/126_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/126_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/126_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Недвижимость</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/147_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/147_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/147_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/147_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Двери купе</h5>

							Звонок 1:
							<audio controls="" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/148_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/148_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/148_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/148_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Туроператор</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/149_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/149_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/149_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/149_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Кредит</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/150_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/150_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/150_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/150_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Двери</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/151_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/151_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/151_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/151_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Поднятие мощности двигателя</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/152_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/152_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/152_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/152_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Школа ин. языков<br><br></h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/153_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/153_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/153_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/153_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Пружины<br><br></h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/154_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/154_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/154_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/154_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Бани и бочки</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/155_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/155_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/155_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/155_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Мебель</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/156_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/156_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/156_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/156_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Авто</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/157_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/157_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/157_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/157_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Садовый измельчитель</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/158_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/158_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/158_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/158_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Проф. образование</h5>

							Звонок 1:
							<audio controls="" class="w-100 p-2" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/159_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/159_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/159_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" class="w-100 p-2" style="margin-top: 10px;">
								<source src="../assets/AboutServicePage/audio/159_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Юр. услуги</h5>

							Звонок 1:
							<audio controls="" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/160_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/160_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/160_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/160_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					<div class="col-md-4" style="margin-top: 30px;">
						<div class="feature-block-one mb-0 ">
							<h5 class="pb-25 tran3s title">Ремонт квартир</h5>

							Звонок 1:
							<audio controls="" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/161_1.mp3" type="audio/mpeg">
							</audio>
							Звонок 2:
							<audio controls="" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/161_2.mp3" type="audio/mpeg">
							</audio>
							Звонок 3:
							<audio controls="" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/161_3.mp3" type="audio/mpeg">
							</audio>
							Звонок 4:
							<audio controls="" style="margin-top: 0px;">
								<source src="../assets/AboutServicePage/audio/161_4.mp3" type="audio/mpeg">
							</audio>
						</div>
					</div>

					


				</div> <!-- /.row -->
			</div>
		</div>




	</div>
	<div style="margin-bottom:70px"></div>
</template>

<script>
export default {
	name: 'AboutServicePage',
	props: {
		msg: String
	}
}
</script>

<style scoped>
body {
	color: white !important;
}

.feature-block-one {
	background: #222222;
	border-radius: 5px;
	overflow: hidden;
	padding: 60px 30px 55px 40px;
	position: relative;
	z-index: 5;
	box-shadow: 0px 30px 70px 0px rgba(255, 177, 33, 0.1);
	height: 100%;
}

h5 {
	font-size: 20px !important;
	font-weight: 700 !important;
	color: #f4b859 !important;
}

p,
h1 {
	color: white;
}

p {
	font-size: 16pt;
}
</style>