<template>

 <div class="container">
        <div class="row">
          <div class="col-lg-5 my-auto">
            <h1 class="mb-4 text-uppercase" >
              <em>Отзывы</em><br class="d-none d-lg-block">
              от наших клиентов
            </h1>
            <p class="mb-0">
              Посмотрите интервью, как наш продукт помогает клиентам получать больше
              продаж в бизнесе?
            </p>
          </div>
          <div class="col-lg-6 offset-lg-1">
            <img src="../assets/Rev/content/hero.png" alt="" class="img-fluid">
          </div>
        </div>
 </div>
      
 <section class="story mt-5">
      <div class="container">
        <div class="item row mb-5">
          <div class="col-lg-5">
            
              <img src="../assets/Rev/story/otziv-2.png" alt="" class="img-fluid w-100">
              <span class="play-btn"><span></span></span>
            
          </div>
          <div class="col-lg-7 ps-lg-4">
            <h5 class="mb-4">Артур Саяхов</h5>
            <p class="mb-4 about">
              <b>Компания:</b> Маруся<br>
              <b>Ниша:</b> Didgital агенство.<br>
              <b>Сайт:</b>
              <a target="_blank" href="https://ra-marussia.ru/">ra-marussia.ru</a>
            </p>
            <p>
              «Для поиска клиентов в свое агентство пользуются нашим сервисом. Уже
              было получено 84 тыс. идентификаций, из них было произведено 45,5
              тыс. диалогов, из них более 7,5 тыс. заявок получили, средняя стоимость
              которых составила 293 рубля. Это сформировало воронку продаж (в том числе и
              ап-сейлы), которая позволила сделать в компании более 50% продаж о твсего
              полученного количества».
            </p>
          </div>
        </div>
        

        <div class="item row mb-5">
          <div class="col-lg-5">
           
              <img src="../assets/Rev/story/otziv-6.png" alt="" class="img-fluid w-100">
              <span class="play-btn"><span></span></span>
           
          </div>
          <div class="col-lg-7 ps-lg-4">
            <h5 class="mb-4">Илья Проскуряков</h5>
            <p class="mb-4 about">
              <b>Компания:</b> ООО АртДэк<br>
              <b>Ниша:</b> Проектирование и строительство: беседки, печи, бани, камины.<br>
              <b>Сайт:</b> <a target="_blank" href="https://artdek.ru/">artdek.ru</a>
            </p>
            <p>
              «Столкнулись с проблемой что бюджет ЯндексДиректа не способен обеспечить
              нужным количеством заявок партнеров. Поэтому в поисках дополнительных
              маркетинговых инструментов для расширения воронки продаж был выбран сервис
              определения номеров телефонов. Как итог в дополнение к стандартным 500
              заявкам появилось 800 определений, из которых 120 шли на контакт, 3 перешли
              в сделки и еще 7 на стадии оформления сделки».
            </p>
          </div>
        </div>
      

        <div class="item row mb-5">
          <div class="col-lg-5">
           
              <img src="../assets/Rev/story/otziv-1.png" alt="" class="img-fluid w-100">
              <span class="play-btn"><span></span></span>
            
          </div>
          <div class="col-lg-7 ps-lg-4">
            <h5 class="mb-4">Юлия</h5>
            <p class="mb-4 about">
              <b>Компания:</b> ЛенРусСтрой<br>
              <b>Ниша:</b> Проектирование, девелопмент и строительство.<br>
              <b>Сайт:</b>
              <a target="_blank" href="https://lenrusstroy.com/">lenrusstroy.com</a>
            </p>
            <p>
              Мы протестировали услугу обзвона
              посетителей сайта, и получили качественные лиды по очень низкой, для сферы
              недвижимости стоимости».
            </p>
          </div>
        </div>
    

        <div class="item row mb-5">
          <div class="col-lg-5">
           
              <img src="../assets/Rev/story/otziv-3.png" alt="" class="img-fluid w-100">
              <span class="play-btn"><span></span></span>
            
          </div>
          <div class="col-lg-7 ps-lg-4">
            <h5 class="mb-4">Руслан</h5>
            <p class="mb-4 about">
              <b>Компания:</b> Дымзавод<br>
              <b>Ниша:</b> Лаунж-бар.<br>
              <b>Сайт:</b> <a target="_blank" href="https://dymzavod.ru/">dymzavod.ru</a>
            </p>
            <p>
              «Перед покупкой сервиса главным вопросом для них было получат ли они новых
              клиентов или потеряют репутацию ресторана вовсе? Но благодаря разработке
              специального скрипта при входе в диалог они достигли результата что всего
              лишь 1 из 15 человек спрашивал о том, откуда был взят его номер. В итоге
              благодаря сервису бюджет на контекстную рекламу был оптимизирован и снижен
              на 30%».
            </p>
          </div>
        </div>
       

        <div class="item row mb-5">
          <div class="col-lg-5">
            
              <img src="../assets/Rev/story/otziv-4.png" alt="" class="img-fluid w-100">
              <span class="play-btn"><span></span></span>
            
          </div>
          <div class="col-lg-7 ps-lg-4">
            <h5 class="mb-4">Евгения Синицына</h5>
            <p class="mb-4 about">
              <b>Компания:</b> Didgital10Х<br>
              <b>Ниша:</b> Didgital агенство.<br>
             
            </p>
            <p>
              «Активно используют сервис для продвижения своих клиентов. На примере
              фитнес-клуба, в котором после использования сервиса продажи увеличились на
              25%, и детского спортивно-развивающего ценра, в котором конверсия по
              продажам достигла 20%. Так же для более эффективной работы с идентификациями
              используются дополнительные инструменты».
            </p>
          </div>
        </div>
      

        <div class="item row mb-5">
          <div class="col-lg-5">
            
              <img src="../assets/Rev/story/otziv-5.png" alt="" class="img-fluid w-100">
              <span class="play-btn"><span></span></span>
            
          </div>
          <div class="col-lg-7 ps-lg-4">
            <h5 class="mb-4">Владислав</h5>
            <p class="mb-4 about">
              <b>Компания:</b> Unimark<br>
              <b>Ниша:</b> Didgital агенство.<br>
              <b>Сайт:</b> <a target="_blank" href="https://unimark.pro/">unimark.pro</a>
            </p>
            <p>
              «Использование сервиса в целях хэджирования риска слива рекламного бюджета и
              необходимость аудита РК перед использованием сервиса. На примере клиента по
              гос. закупкам с чеком порядка 10 млн. рублей показана неоспоримая польза
              сервиса, даже в случае низкой конверсии».
            </p>
          </div>
        </div>
        
      </div>
    </section> 
  </template>
  
  <script>
  export default {
    name: 'ReviewsPage',
    props: {
      msg: String
    }
  }
  </script>
  
<style scoped>
p , h1 {
  color: white;
}

p{
  font-size: 16pt;
}

.story .item .about a {
    color: #b4b3b3;
}
a {
    cursor: pointer;
    text-decoration: none;
    color: #ffffff;
}
h5 {
    font-size: 25px;
    font-weight: 600;
    color: #f4b859;
}

img{
  border-radius: 20px;
}
</style>