<template>
  <div class="hello">
    <AboutServicePage msg="service" />
  </div>
  
</template>

<script>
// @ is an alias to /src
import AboutServicePage from '@/components/AboutServicePage.vue'

export default {
  name: 'AboutServiceView',
  components: {
    AboutServicePage
  }
}
</script>


<style scoped>

</style>