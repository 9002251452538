<template>
  <div class="hello">
    <ReviewsPage msg="Reviews" />
  </div>
  
</template>


<script>
// @ is an alias to /src
import ReviewsPage from '@/components/ReviewsPage.vue'

export default {
  name: 'ReviewsView',
  components: {
    ReviewsPage
  }
}
</script>

<style scoped>
body {
    background-color: #282828;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: #ffffff;
    font-size: 18px;
    overflow-x: hidden;
}
.privacy-link {
    text-decoration: underline;
}
a {
    cursor: pointer;
    text-decoration: none;
    color: #ffffff;
}

h1 em, h2 em, h3 em {
    font-style: normal;
    color: #f4b859;
}

.btn {
    padding: 14px;
    min-height: 48px;
    border-radius: 10px;
    background-color: #f4b859;
    border: 1px solid #f4b859;
    font-size: 15px;
    line-height: 18px;
    font-weight: 600;
    color: #282828;
    cursor: pointer;
}

input[type=text], input[type=email] {
    padding: 25px;
    width: 100%;
    max-width: 380px;
    height: 76px;
    border-radius: 10px;
    background-color: #acacac;
    text-align: center;
    color: #e4e4e4;
    font-size: 20px;
    line-height: 24px;
}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
</style>


